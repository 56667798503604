<script setup lang="ts">
import { computed } from 'vue'
import { KsButton, KsIcon } from '@aschehoug/kloss'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/stores/auth'
import useLicenseControlStore from '~/stores/licenseControl'
import CloseButton from '~/components/utils/CloseButton.vue'

const licenseControlStore = useLicenseControlStore()
const { isTeacher } = storeToRefs(useAuthStore())
const { resetLicenseControlStore } = licenseControlStore
const { hasAccessRestricted } = storeToRefs(licenseControlStore)
const { t } = useI18n()

const webshopUrl = computed(() => <string>import.meta.env.VITE_WEBSHOP_TRIALURL)

const washPage = () => {
  /**
   * This function first forces scroll to top of page for more consistent results,
   * then it removes elements that are not visible in the top half of the view
   */
  window.scrollTo(0, 0)
  const parentElement = document.getElementById('app-content')
  if (!parentElement) return
  const elements = parentElement.querySelectorAll('*')

  Array.from(elements).forEach(element => {
    observer.observe(element)
  })
}

const isIgnored = (element: IntersectionObserverEntry): boolean => {
  const elementIgnored = element.target.hasAttribute('license-control-ignore')
  const parentIgnored = element.target.parentElement?.hasAttribute('license-control-ignore') ?? false
  return elementIgnored || parentIgnored
}

const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if(isIgnored(entry)) {
        // Add attribute to allow inheritance of ignore
        entry.target.setAttribute('license-control-ignore', '')
        return
      }
      if (!entry.isIntersecting && !isIgnored(entry)) {
        entry.target.remove()
      }
    })},
  {
    root: null,
    rootMargin: '0px 0px -50% 0px',
  }
)
</script>

<template>
  <Transition
    v-if="hasAccessRestricted"
    tag="div"
    class="license-control-ignore z-20"
    enter-active-class="duration-500 delay-500"
    enter-from-class="translate-y-full opacity-0"
    enter-to-class="translate-y-0 opacity-100"
    appear
    license-control-ignore
    @after-enter="washPage"
  >
    <div class="absolute h-screen w-screen bg-gradient-to-b from-transparent via-white to-white">
      <div class="container mx-auto h-screen">
        <div class="absolute inset-x-0 bottom-10 z-20 m-auto h-fit max-w-prose rounded-lg border-gray-10 bg-white p-10 text-center sm:bottom-32 sm:border-2">
          <div class="mb-3 inline-flex rounded-full bg-seagreen-10/50 p-2 text-seagreen-50">
            <KsIcon
              icon="lock"
              :scale="1.8"
            />
          </div>
          <h3
            class="text-2xl font-bold"
            v-text="t('licenseControl.message.title')"
          />
          <p
            class="mb-5 mt-3"
            v-text="isTeacher ? t('licenseControl.message.body.teacher') : t('licenseControl.message.body.student')"
          />
          <div
            class="grid w-full grid-cols-1 gap-4"
            :class="{ 'sm:grid-cols-2': isTeacher }"
          >
            <CloseButton
              variant="primary"
              size="medium"
              shape="normal"
              classes="relative"
              :text="t('licenseControl.buttons.back')"
              center
              @click="resetLicenseControlStore()"
            />
            <KsButton
              v-if="isTeacher"
              variant="secondary"
              size="medium"
              :href="webshopUrl"
              target="_blank"
              center
            >
              {{ t('licenseControl.buttons.webshop') }}
            </KsButton>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>
