<script setup lang="ts">
import type { ContentResource } from '~/models/Content/ContentResource'
import { computed } from 'vue'
import { KsIcon } from '@aschehoug/kloss'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import { useContentType } from '~/composables/useContentType'

export interface CollectionProps {
  props: ContentResource
  resources: ContentResource[]
}

const { props, resources } = defineProps<CollectionProps>()
const { buildResourceUrl } = useUrlBuilder()
const { getName } = useContentType()

const contentTypeName = computed(() => resources.length > 0 ? getName(resources[0]) : '')

</script>

<template>
  <article
    v-if="resources.length"
    class="mx-auto h-full w-full max-w-screen-au px-4 sm:px-8"
  >
    <a
      :href="buildResourceUrl(resources[0])"
      class="
        group flex min-h-[300px] w-full flex-col overflow-hidden rounded-2xl
        border-2 border-gray-10 transition-all hover:shadow-lg focus-visible:ring sm:flex-row
      "
      :class="{'sm:flex-row-reverse': props.alignText}"
    >
      <div
        class="aspect-video max-h-[250px] w-full min-w-full sm:max-h-full sm:min-w-0"
        :style="`background: ${props.colors[0] ? props.colors[0] : 'rgb(var(--au-gray-40))'}`"
      >
        <img
          v-if="!props.imageIsFallback || !resources[0].imageIsFallback"
          :src="!props.imageIsFallback ? props.image : resources[0].image"
          class="block h-full w-full overflow-hidden object-cover object-center"
          alt=""
        >
      </div>
      <div class="flex w-full flex-col justify-center bg-white p-6 transition-colors sm:p-10">
        <p
          v-if="contentTypeName"
          class="mb-4 font-semibold text-seagreen-40"
          v-text="contentTypeName"
        />
        <h2 class="mb-2 text-2xl font-bold text-blue-50 sm:text-3xl">
          <span class="relative block w-fit">
            {{ props.title }}
            <KsIcon
              icon="arrow-right"
              scale="0.7"
              class="mb-1 ml-1 inline-block transition-all group-hover:ml-2 group-focus-visible:ml-2"
            />
          </span>
        </h2>
        <p
          class="text-base text-gray-40 md:w-5/6"
          v-html="props.description"
        />
      </div>
    </a>
  </article>
</template>
