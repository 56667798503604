<script setup lang="ts">
import { computed } from 'vue'
import { KsIcon } from '@aschehoug/kloss'
import { useSectionStore } from '~/stores/section'
import { storeToRefs } from 'pinia'
import { ContentType } from '~/models/Content/ContentType'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import LinkOrAnchor from '~/components/utils/LinkOrAnchor.vue'

const sectionStore = useSectionStore()
const { loadSections } = sectionStore
const { buildResourceUrl } = useUrlBuilder()
const { userSections, isLoading } = storeToRefs(sectionStore)

loadSections(import.meta.env.VITE_SECTION_PARENT_ID)

const standaloneSection = computed(() => userSections.value
  .filter(section => section.resources.length > 0)
  .filter(section => section.props.contentTypeIdentifier === ContentType.SectionStandalone)[0])

const url = computed(() => buildResourceUrl(standaloneSection.value?.resources[0]))
</script>

<template>
  <div class="group w-[350px] rotate-1 rounded bg-yellow-30 transition-all duration-300 hover:-rotate-[6deg]">
    <div
      v-if="userSections.length && standaloneSection && !isLoading"
      class="relative flex max-w-[350px] -rotate-6 flex-col gap-2 rounded bg-yellow-10 px-6 py-4 text-black shadow transition-all duration-300 group-hover:rotate-[6deg]"
    >
      <span
        class="text-sm font-semibold uppercase tracking-wider"
        v-text="standaloneSection.resources[0].title"
      />
      <span class="font-calistoga !leading-8 fluid-text-lg">
        <LinkOrAnchor
          class="line-clamp-3 outline-0 before:absolute before:inset-0 before:rounded before:transition before:hover:shadow-xl before:focus-visible:ring"
          :link="url"
        >
          {{ standaloneSection.props.title }}
        </LinkOrAnchor>
      </span>
      <RichTextRenderer
        class="text-gray-50"
        :text="standaloneSection.props.description"
      />
      <KsIcon
        icon="long-arrow-right"
        class="transition-all duration-500 group-hover:ml-6"
        :scale="1.5"
      />
    </div>
  </div>
</template>
