<script lang="ts" setup>
import { KsButton } from '@aschehoug/kloss'
import { useI18n } from 'vue-i18n'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import useFullscreen from '~/composables/useFullscreen'

const { t } = useI18n()
const {
  isSupported,
  toggleFullscreen,
  isFullscreen,
  disableFullscreen,
  onFullscreenChange,
  setCurrentFullscreenState,
} = useFullscreen()

const browserWidth = ref<number>()

const buttonBreakpoint = 767 // Allow small tablets to access fullscreen
const ariaLabel = computed(() => isFullscreen.value ? t('fullscreen.close') : t('fullscreen.open'))
const showButton = computed(() => (browserWidth.value && browserWidth.value > buttonBreakpoint) || isFullscreen.value)

onMounted( () => {
  if (isSupported()) {
    addEventListener('fullscreenchange', onFullscreenChange)
    setCurrentFullscreenState()
    addEventListener('resize', updateWidth)
    updateWidth()
  }
})

onUnmounted(() => {
  if (isSupported()) {
    removeEventListener('fullscreenchange', onFullscreenChange)
    removeEventListener('resize', updateWidth)
    disableFullscreen()
  }
})

const updateWidth = () => {
  browserWidth.value = window.innerWidth
}
</script>

<template>
  <KsButton
    v-if="isSupported() && showButton"
    variant="secondary"
    shape="round"
    size="large"
    class="z-10 transition duration-200"
    :aria-label="ariaLabel"
    :icon-left="isFullscreen ? 'compress' : 'expand'"
    @click="toggleFullscreen"
  />
</template>
