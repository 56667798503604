<script lang="ts" setup>
import type { ContentResource } from '~/models/Content/ContentResource'
import { KsCarousel, KsCarouselItem, KsIcon } from '@aschehoug/kloss'
import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'
import { useUrlBuilder } from '~/composables/useUrlBuilder'
import useColors from '~/composables/colors'
import LinkOrAnchor from '~/components/utils/LinkOrAnchor.vue'

export interface CollectionProps {
  props: ContentResource
  featured: ContentResource[]
  resources: ContentResource[]
}

const { props, featured, resources } = defineProps<CollectionProps>()

const backgroundElement = ref()

const { backgroundStyle, needsDarkText } = useColors({
  identifier: props.locationId!,
  backgroundColor: props.colors[0],
  backgroundFormatter: (color) => `linear-gradient(180deg, ${color}, ${color}E6)`,
  elementRef: backgroundElement,
})

const textStyle = computed(() =>
  needsDarkText.value
    ? 'text-black'
    : 'text-white')

const { t } = useI18n()
const { buildResourceUrl } = useUrlBuilder()

const mainResourceUrl = computed(() => {
  if (resources.length > 0) {
    return buildResourceUrl(resources[0]) as string
  }
  return ''
})
</script>

<template>
  <section
    v-if="resources.length || featured.length"
    class="mx-auto w-full overflow-hidden sm:max-w-screen-au sm:px-8"
  >
    <div
      ref="backgroundElement"
      class="px-4 py-8 sm:rounded-2xl sm:p-14"
      :style="backgroundStyle"
      :class="textStyle"
    >
      <h2
        class="mb-2 text-3xl font-bold sm:text-4xl"
        v-text="props.title"
      />
      <p
        v-if="props.description"
        class="max-w-prose text-base"
        v-html="props.description"
      />
      <LinkOrAnchor
        v-if="resources.length"
        class="mt-4 inline-flex items-center gap-2 font-medium hover:underline focus-visible:ring"
        :link="mainResourceUrl"
      >
        {{ t('links.seeAll') }}
        <KsIcon icon="arrow-right" />
      </LinkOrAnchor>
      <KsCarousel v-if="featured.length">
        <KsCarouselItem
          v-for="item in featured"
          :key="item.locationId"
        >
          <slot
            name="resource"
            :resource-item="item"
          />
        </KsCarouselItem>
      </KsCarousel>
    </div>
  </section>
</template>
